import React from 'react';

export default function PopupMessage({ message, type = "info", show, onClose }) {
    if (!show) return null;

    const bgColor = type === "error" ? "bg-rougeBlanc" : "bg-vertLogo";
    const textColor = "text-blanc";

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
            <div className={`p-4 rounded-lg shadow-lg max-w-sm w-full ${bgColor} ${textColor}`}>
                <div className="flex justify-between items-center">
                    <h2 className="font-bold">{type === "error" ? "Erreur" : "Info"}</h2>
                    <button 
                        className="text-xl font-bold"
                        onClick={onClose}
                    >
                        &times;
                    </button>
                </div>
                <p>{message}</p>
                <button 
                    className="mt-4 px-4 py-2 bg-white text-gray-800 rounded-md hover:bg-gray-200"
                    onClick={onClose}
                >
                    Fermer
                </button>
            </div>
        </div>
    );
}
