import React, {useState, useEffect} from 'react';
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import {registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import {Link} from "react-router-dom";
import VisitePro from '../Containers/VisitePro';
import Modal from 'react-modal';
import db from '../../Db/db';

registerLocale('fr', fr);
setDefaultLocale('fr');
Modal.setAppElement('#root');

export default function Visites() {

    const [selectedPrevoir, setSelectedPrevoir] = useState([]);
    const [submitData, setSubmitData] = useState([]);
    const [visitesData, setvisitesData] = useState([]);
    const [activite, setActivite] = useState([]);
    const [actClient, setActClient] = useState([]);
    const [departClient, setDepartClient] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const data = await db.visites.toArray();
            setvisitesData(data.sort((a, b) => new Date(b.dateVisite) - new Date(a.dateVisite)));
            const data2 = await db.visitePrevoir.toArray();
            setSelectedPrevoir(data2);
            const data3 = await db.act.toArray();
            setActivite(data3)
            const data4 = await db.activite.toArray();
            setActClient(data4)
            const data5 = await db.depart.toArray();
            setDepartClient(data5)

        }

        fetchData();
    }, []);

    


    const handleSubmit = (submitFilterData) => {
        setSubmitData(submitFilterData)
    };

    

    let allVisit = visitesData;

    if (submitData.searchTerm && submitData.searchTerm.trim() !== '') {
        const searchTermParts = submitData.searchTerm.split(' / ');
        const clientCode = searchTermParts[0]?.trim();
        const agenceCode = searchTermParts[2]?.trim();
        
        if (clientCode) {
            allVisit = allVisit.filter(vi => vi.codeClient === clientCode);
        }
        if (agenceCode) {
            allVisit = allVisit.filter(vi => vi.codeAgence === agenceCode);
        }
    }
    
    if (submitData.commercial && submitData.commercial.length > 0) {
        allVisit = allVisit.filter(vi => vi.idUser == submitData.commercial);
    }
    
    if (submitData.selectedAgence && submitData.selectedAgence.length > 0) {
        allVisit = allVisit.filter(vi => vi.codeAgence === submitData.selectedAgence);
    }
    
    if (submitData.selectedOptions && submitData.selectedOptions.length > 0) {
        const selectedActivityIds = submitData.selectedOptions.map(option => option.value);
    
        allVisit = allVisit.filter(vi => {
            const clientActivity = activite.find(act => act.codeClient === vi.codeClient && act.codeAgence === vi.codeAgence);
            if (!clientActivity) return false;
    
            const clientActivityIds = clientActivity.idActMF ? clientActivity.idActMF.split(', ').map(id => parseInt(id.trim())) : [];
            
            return selectedActivityIds.some(id => clientActivityIds.includes(id));
        });
    }
    
    if (submitData.startDate && submitData.endDate) {
        allVisit = allVisit.filter(vi => {
            const visitDate = new Date(vi.dateVisite);
            return visitDate >= new Date(submitData.startDate) && visitDate <= new Date(submitData.endDate);
        });
    }
    
    console.log(allVisit);

    

    const getLibelleActiviteFromClient = (codeClient, codeAgence) => {
        if (!activite || !actClient) return { displayLabel: "", fullLabel: [] };
    
        const actEntry = activite.find(act => act.codeClient === codeClient && act.codeAgence === codeAgence);
    
        if (!actEntry || !actEntry.idActMF) return { displayLabel: "", fullLabel: [] };
    
        const idsActivite = actEntry.idActMF.split(', ').map(id => parseInt(id.trim()));
    
        const activitesLibelle = idsActivite.map(id => {
            const activite = actClient.find(act => act.value === id);
            return activite ? activite.label : "";
        });
    
        if (activitesLibelle.length > 1) {
            return {
                displayLabel: `${activitesLibelle[0]}, ...`,
                fullLabel: activitesLibelle
            };
        }
    
        return {
            displayLabel: activitesLibelle[0] || "",
            fullLabel: activitesLibelle[0] ? [activitesLibelle[0]] : []
        };
    };

    const getDepartClient = (codeClient, codeAgence) => {
        if (!departClient) return { displayDepart: "", fullDepart: [] };

        const departEntries = departClient.filter(dep => dep.codeClient === codeClient && dep.codeAgence === codeAgence);

        if (!departEntries || departEntries.length === 0) return { displayDepart: "", fullDepart: [] };

        const departLibelle = departEntries.map(dep => `${dep.ville}, ${dep.pays} (${dep.cp})`);

        if (departLibelle.length > 1) {
            return {
                displayDepart: `${departLibelle[0]}, ...`,
                fullDepart: departLibelle
            };
        }

        return {
            displayDepart: departLibelle[0],
            fullDepart: departLibelle
        };
    };

    

    return (
        <div className='flex flex-row h-full w-full fixed'>

            <Sidebar />

            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">

                    <Title title="Mes Visites" btn={true} onSubmitFilter={handleSubmit}/>

                </div>
                <div className='flex h-[85%] w-full mt-[3%] px-4'>

                    <div className=" w-[70%] max-sm:w-[100%] h-full">

                        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-[90%]'>
                            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                                <span>Mes visites passées</span>
                            </div>
                            <div className='flex items-center justify-center'>
                                <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[98%] border-collapse ">
                                    <thead className='h-[6%] max-h-1.5 text-blanc'>
                                        <tr className='bg-marineLogo'>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Nom client</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Agence</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Activité</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Secteur</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Points de départ</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Commercial</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center">Date du RDV</th>
                                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center"></th>
                                        </tr>
                                    </thead>
                                    <tbody className='overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                        {allVisit.map(visit => {
                                            const { displayLabel, fullLabel } = getLibelleActiviteFromClient(visit.codeClient, visit.codeAgence);
                                            const { displayDepart, fullDepart } = getDepartClient(visit.codeClient, visit.codeAgence);
                                            return (
                                                <tr key={visit.id} className='bg-white'>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-left">
                                                        <Link to={"/profil/client/" + visit.codeClient + "/" + visit.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                                            <span>{visit.nomClient}</span>
                                                        </Link>
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.codeAgence}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center relative group">
                                                        <span className="cursor-pointer">{displayLabel}</span>
                                                        {fullLabel.length > 1 && (
                                                            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-marineLogo text-left text-blanc z-10 text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <ul className="list-disc list-inside">
                                                                    {fullLabel.map((label, index) => (
                                                                        <li key={index}>{label}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.activite}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center relative group">
                                                        <span className="cursor-pointer">{displayDepart}</span>
                                                        {fullDepart.length > 1 && (
                                                            <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 px-2 py-1 bg-marineLogo text-left text-blanc z-10 text-xs rounded-lg shadow-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                                                                <ul className="list-disc list-inside">
                                                                    {fullDepart.map((depart, index) => (
                                                                        <li key={index}>{depart}</li>
                                                                    ))}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {visit.firstname} {visit.name}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        {new Date(visit.dateVisite).toISOString().slice(0, 10).replace("T", " ")}
                                                    </td>
                                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs text-center">
                                                        <Link to={"/newVisite/" + visit.id} className="inline-flex items-center px-4 py-2 text-sm font-medium text-blanc font-bold bg-vertLogo rounded-lg hover:bg-blanc hover:text-vertLogo hover:border hover:border-vertLogo">Synthèse</Link>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col p-8 w-[30%] h-full max-sm:hidden">
                    <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-[50%] w-[100%] mb-[5%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
                            
                            <div className='flex justify-center border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                                </svg>
                                <p>Mes visites à prévoir</p>
                            </div>
                            <div className='flex-shrink max-h-[90%]  w-full mt-[5%] font-bahn overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100'>
                                {selectedPrevoir.map(client => (
                                    <div className="w-full bg-white border-b border-bleu m-[1%]">
                                        <div className="flex flex-row w-full h-full">
                                        <Link to={"/profil/client/" + client.codeClient + "/" + client.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                                <div className="h-full">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#113577" className="w-8 h-full">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
                                                    </svg>
                                                </div>
                                                <div>
                                                    <ul className="text-xs w-full text-marineLogo">
                                                        <li className="w-full rounded-sm">
                                                            <span className=" w-full font-bahn">{client.nomClient}</span>
                                                        </li>
                                                        <li className=" w-full rounded-sm">
                                                            <span className=" w-full font-bahn">{client.codeClient}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <VisitePro />
                        
                    </div>
                </div>
            </div> 
        </div>
    );
}