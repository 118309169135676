import React, { useState, useEffect } from 'react';
import axios from "axios";
import {useNavigate} from "react-router-dom";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import { Calendar,  momentLocalizer } from "react-big-calendar";
import moment from 'moment';
import 'moment/locale/fr';
import "react-big-calendar/lib/css/react-big-calendar.css";
import dateFormat from "dateformat"
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest, graphConfig } from "../../config/authconfig";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


moment.locale('fr');

const localizer = momentLocalizer(moment);

export default function Agenda({url}) {

    const navigate = useNavigate();
    const [eventsData, setEventsData] = useState();
    const [graphData, setGraphData] = useState([]);
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const { instance, accounts }  = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [selectedDateDeb, setSelectedDateDeb] = useState(new Date());
    const [selectedDateFin, setSelectedDateFin] = useState(new Date());

    const [clientList, setClientList] = useState([]);

    const [eventData, setEventData] = useState({
        start: null,
        end: null,
    });

    const handleDateChangeDeb = (dateDeb) => {
        setSelectedDateDeb(dateDeb);
    };
    const handleDateChangeFin = (dateFin) => {
        setSelectedDateFin(dateFin);
    };
    const signInClickHandler = (loginType) => {
           
        if (loginType === "popup") {
            instance.loginPopup(loginRequest)
            .catch(e => {
                console.log(e);   
            });
        }
    }
    const signOutClickHandler= (logoutType) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/agenda"
            });
                
        }
    }
    const handleCreate = (e) =>{
        e.preventDefault();
        const [datePart, timePart] = (e.target.startDate.value).split(' ')
        const [day, month, year] = datePart.split('/');
        const formattedDate = `${year}-${month}-${day} ${timePart}:00`;

        const [datePartf, timePartf] = (e.target.endDate.value).split(' ')
        const [dayf, monthf, yearf] = datePartf.split('/');
        const formattedDatef = `${yearf}-${monthf}-${dayf} ${timePartf}:00`;
        const client = e.target.codeClient.value
        const codeClient = client.split(' - ')[2]
        const agence = client.split(' - ')[1]
        axios.post(url+'calendar/', 
        {
            codeClient: codeClient,
            codeAgence: agence,
            title: e.target.object.value,
            location: e.target.location.value,
            idUser: userInfo.user,
            start: formattedDate,
            end: formattedDatef
        },{
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            },
        }).then((response) => {
            close();
            chargeBdd();
        }).catch((error) => {
            console.error(error);
        });

        const request = {
            ...loginRequest,
            account: accounts[0]
        };
        instance.acquireTokenSilent(request).then((response) => {
            axios.post(graphConfig.graphMyCalendarPost, {
                subject: e.target.object.value,
                start: {
                    dateTime: formattedDate,
                    timeZone: 'Europe/Paris'
                },
                end: {
                    dateTime: formattedDatef,
                    timeZone: 'Europe/Paris'
                },
                attendees: [
                    {
                        emailAddress: {
                            address: e.target.email.value
                        },
                        type: 'Required'
                    }
                ],
                location: {
                    displayName: e.target.location.value,
                    locationType: 'Default'
                },
            }, {
                headers: {
                    'Authorization': 'Bearer ' + response.accessToken
                }
            })
        });

    }

        function chargedClickHandler() {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            
            instance.acquireTokenSilent(request).then((response) => {
                axios.get(graphConfig.graphMyCalendarGet, {
                    headers: {
                        'Authorization': 'Bearer ' + response.accessToken
                    }
                })
                .then(res => {
                    

                    setGraphData(eve => res.data.value.map(c => {
                        const startDateTime = new Date(c.start.dateTime);
                        const endDateTime = new Date(c.end.dateTime);
                    
                        const timezoneOffset = startDateTime.getTimezoneOffset();
                        const hoursToAdd = timezoneOffset === -120 ? 2 : 1;
                    
                        let adjustedStart = new Date(startDateTime.setHours(startDateTime.getHours() ));
                        let adjustedEnd = new Date(endDateTime.setHours(endDateTime.getHours() ));
                    
                        if (startDateTime.getHours() === 0) {
                            adjustedStart = new Date(adjustedStart.setHours(startDateTime.getHours()));
                            
                        }else{
                            adjustedStart = new Date(adjustedStart.setHours(startDateTime.getHours() + hoursToAdd));
                        }
                    
                        if (endDateTime.getHours() === 0) {
                            adjustedEnd = new Date(adjustedEnd.setHours(endDateTime.getHours()));
                        }else{
                            adjustedEnd = new Date(adjustedEnd.setHours(endDateTime.getHours() + hoursToAdd));
                        }
                        return {
                            title: c.subject,
                            start: adjustedStart,
                            end: adjustedEnd,
                            locationRdv: c.location.displayName,
                            codeClient: (c.attendees).length !== 0 ? c.attendees[0].emailAddress.address : ''
                        };
                    }));
                })
                .catch(error => console.log(error));
            }).catch((e) => {
                instance.acquireTokenPopup(request).then((response) => {
                    axios.get(loginRequest.graphMyCalendarGet, {
                        headers: {
                            'Authorization': 'Bearer ' + response.accessToken
                        }
                    })
                    .then(res => {
                        setGraphData(eve => res.data.value.map(c => ({
                            title: c.subject,
                            start: new Date(c.start.dateTime).getTimezoneOffset() === -120 ? new Date(new Date(c.start.dateTime).setHours(new Date(c.start.dateTime).getHours()+2)):  new Date(new Date(c.start.dateTime).setHours(new Date(c.start.dateTime).getHours()+1)),
                            end: new Date(c.end.dateTime).getTimezoneOffset() === -120 ? new Date(new Date(c.end.dateTime).setHours(new Date(c.end.dateTime).getHours()+2)) :  new Date(new Date(c.end.dateTime).setHours(new Date(c.end.dateTime).getHours()+1)),
                            locationRdv: c.location.displayName,
                            codeClient: (c.attendees).length !== 0 ? c.attendees[0].emailAddress.address : ''
                        
                    })));
                    })
                    .catch(error => console.log(error));
                });
            });
            
        }

        function chargeBdd(){
            axios.get(url+'calendar/'+userInfo.user, {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    'Authorization': 'Bearer ' + userInfo.token,
                }
            })
            .then(res => {     
                const tab = (res.data).map(event => ({
                    title: event.title,
                    
                    start: new Date(event.start).getTimezoneOffset() === -120 ? new Date(new Date(event.start).setHours(new Date(event.start).getHours()-2)) : new Date(new Date(event.start).setHours(new Date(event.start).getHours()-1)),
                    end: new Date(event.end).getTimezoneOffset() === -120 ? new Date(new Date(event.end).setHours(new Date(event.end).getHours()-2)) : new Date(new Date(event.end).setHours(new Date(event.end).getHours()-1)),
                    id : event.id,
                    locationRdv : event.locationRdv,
                    codeClient : event.codeClient,
                  }));
                setEventsData(tab)
                
            })
            .catch(error=>{
                if(error.response.status === 401){
                    navigate("/login");
                }
            })
        }
    useEffect(() => {
        
        fetchClient();
        chargedClickHandler();
        chargeBdd();

        
        }, [url]);

        const fetchClient = async () => {
            try {
              const response = await axios.get(url+'client/allClients', {
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "Application/json",
                    'Authorization': 'Bearer ' + userInfo.token,
                }
            }); 
              const data = response.data; 
        
              setClientList(data);
            } catch (error) {
              console.error(error);
            }
        };

        
        

    function close() {
        document.getElementById("newEvent").classList.add('hidden');
        document.getElementById("event").classList.add('hidden');
    }

    const updEvent = (e) =>{
        e.preventDefault();
        const title = e.target.titre.value;
        const start = moment(e.target.start.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
        const end = moment(e.target.end.value, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')
        const locationRdv = e.target.locationRdv.value
        const client = e.target.codeClient.value
        const codeClient = client.split(' - ')[2]
        const agence = client.split(' - ')[1]

        axios.put(url+'calendar/upd/'+e.target.id.value, 
        {
            title: title,
            start: start,
            end: end,
            idUser: userInfo.user,
            locationRdv : locationRdv,
            codeClient : codeClient,
            agence: agence
        },
        {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token,
            }
        }).then((response) => {
            close()
          chargeBdd();
          
        })
        .catch((error) => {
          console.error(error);
        });
    }

    function eventStyleGetter(){
        var style = {
            backgroundColor: "#f1a151",
        };
        return {
            style: style
        };
    }
    function eventStyleGetter2(){
        var style = {
            backgroundColor: "#58d26e",
        };
        return {
            style: style
        };
    }

    const handleSelect = ({ start, end }) => {
        const startClock = dateFormat(start, 'HH:MM')
        const endClock = dateFormat((moment(end, 'HH:MM')).subtract(1, 'minutes'), 'HH:MM');
        const startDate = dateFormat(start, 'dd/mm/yyyy')
        const endDate =  dateFormat((moment(end, 'dd/mm/yyyy')).subtract(1, 'days'), 'dd/mm/yyyy');

        document.getElementById("newEvent").classList.remove('hidden');
        document.getElementById('startDate').value = startDate+" "+startClock;
        document.getElementById('endDate').value = endDate+" "+endClock;


        
    };
    const eventSelect = (event) => {
        document.getElementById("event").classList.remove('hidden');
        setEventData(event)
    };

    const handleEndDateChange = (date) => {
        setEventData({ ...eventData, end: date });
    };
    const handleStartDateChange = (date) => {
        setEventData({ ...eventData, start: date });
    };

    return (
        <>
        <div className='flex flex-row h-full w-full fixed z-0'>
            <Sidebar />
            <div className="relative flex flex-col w-full">
                
                <Title title="Mon Agenda"/>
                { isAuthenticated ? <div className='flex flex-row-reverse'><button className='hover:bg-blanc bg-vertLogo text-blanc hover:text-vertLogo py-2 px-4 hover:border hover:border-vertLogo rounded' onClick={chargedClickHandler}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6" onClick={chargedClickHandler}><path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" /></svg></button>
                <button className='hover:bg-blanc bg-vertLogo text-blanc hover:text-vertLogo py-2 px-4 hover:border hover:border-vertLogo rounded' onClick={() => signOutClickHandler("popup")}>Se deconnecter de Outlook</button></div> : <div className='flex flex-row-reverse'><button className=' hover:bg-blanc bg-vertLogo text-blanc hover:text-vertLogo py-2 px-4 hover:border hover:border-vertLogo rounded' onClick={() => signInClickHandler("popup")}>Me connecter sur mon calendrier Outlook</button></div> }
                
                <div className="md:p-8">
                { isAuthenticated ?
                    <Calendar 
                    views={["day", "agenda", "work_week", "month"]}
                    selectable 
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    defaultDate={new Date()}
                    defaultView="month"
                    style={{ height: "80vh" }}
                    events={graphData}
                    onSelectSlot={handleSelect}
                    onSelectEvent={eventSelect}
                    messages={{
                        next: "Suivant",
                        previous: "Précedant",
                        today: "Aujourd'hui",
                        month: "Mois",
                        work_week: "Semaines",
                        day: "Jours"
                      }}
                      eventPropGetter={eventStyleGetter}
                    />
                  
                   
                    :
                    <Calendar 
                    views={["day", "agenda", "work_week", "month"]}
                    selectable 
                    localizer={localizer}
                    startAccessor="start"
                    endAccessor="end"
                    defaultDate={new Date()}
                    defaultView="month"
                    style={{ height: "80vh" }}
                    events={eventsData}
                    onSelectSlot={handleSelect}
                    onSelectEvent={eventSelect}
                    messages={{
                        next: "Suivant",
                        previous: "Précedant",
                        today: "Aujourd'hui",
                        month: "Mois",
                        work_week: "Semaines",
                        day: "Jours"
                      }}
                      eventPropGetter={eventStyleGetter2}
                    />
                    }
                </div>
            </div>
        </div>
            <div id='newEvent' className={'xl:w-2/6 h-32 absolute hidden md:top-[10%] md:left-[39%] '}>
                <form onSubmit={handleCreate}>
                <div role="alert" className="container bg-blanc mx-auto w-12/12 xl:w-2/3 xl:max-w-lg">
                    <div className="relative py-8 px-5 md:px-10 bg-blanc shadow-md rounded border border-grey">
                        <h1 className="text-bleu font-lg font-bold tracking-normal leading-tight mb-4">Nouvel evenement</h1>


                        <label for="object" className="text-bleu text-sm font-bold leading-tight tracking-normal">Titre</label>

                        <input type='text' id="object" name="object" placeholder="Titre de l'évenement" className="mb-5 mt-2 text-gray-600 focus:outline-none focus:border focus:border-bleu font-normal w-full h-10 flex items-center pl-3 text-sm border-grey rounded border"  />
                        
                        <label for="dateDe" className="text-bleu text-sm font-bold leading-tight tracking-normal">Début</label>
                        <div className="relative flex mb-5 mt-2 items-center rounded border border-grey pr-px focus-within:border-bleu">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-[3%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                            <DatePicker id="startDate" name='startDate' placeholderText="jj/mm/aaaa hh:mm" className="ml-[2%] text-gray-600 focus:outline-none font-normal  h-10 flex items-center text-sm" showTimeSelect locale="fr" dateFormat="dd/MM/yyyy HH:mm" selected={selectedDateDeb} onChange={handleDateChangeDeb} />
                            
                        </div>

                        <label for="dateFin" className="text-bleu text-sm font-bold leading-tight tracking-normal">Fin</label>
                        <div className="relative flex mb-5 mt-2 items-center rounded border border-grey pr-px focus-within:border-bleu">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-[3%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                            <DatePicker id="endDate" name='endDate' placeholderText="jj/mm/aaaa hh:mm" className="ml-[2%] text-gray-600 focus:outline-none font-normal  h-10 flex items-center text-sm border-grey" showTimeSelect locale="fr" dateFormat="dd/MM/yyyy HH:mm" selected={selectedDateFin} onChange={handleDateChangeFin} />
                            
                        </div>

                        <label for="location" className="text-bleu text-sm font-bold leading-tight tracking-normal">Emplacement</label>
                        <div className="relative mb-5 mt-2">
                            
                            <input type='text' id="location" name='location' placeholder="Lieu du rendez-vous" className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-bleu font-normal w-full h-10 flex items-center pl-3 text-sm border-grey rounded border"  />
                        
                        </div>
                        <label for="location" className="text-bleu text-sm font-bold leading-tight tracking-normal">Client</label>
                        <div className="relative mb-5 mt-2">
                            <input type="text" list="datalistOptions" name="codeClient" id="codeClient"  placeholder="nom du client" className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-bleu font-normal w-full h-10 flex items-center pl-3 text-sm border-grey rounded border"  />
                            <datalist id="datalistOptions">
                                {clientList.map((item) => (
                                <option key={item.codeClient + item.codeAgence} value={`${item.nomClient} - ${item.codeAgence} - ${item.codeClient}`} />
                                ))}
                            </datalist>
                        </div>
                        { isAuthenticated ?
                        <div>
                            <label for="email" className="text-bleu text-sm font-bold leading-tight tracking-normal">Email de l'invité</label>
                            <div className="relative mb-5 mt-2">
                                <input type="text" name="email" placeholder="adresse email de l'invité" className="mb-8 text-gray-600 focus:outline-none focus:border focus:border-bleu font-normal w-full h-10 flex items-center pl-3 text-sm border-grey rounded border"  />
                                
                            </div>
                        </div>
                        : <div></div>}

                        <div className="flex items-center justify-start w-full">
                            <input type='submit' className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 bg-indigo-700 hover:bg-indigo-600 border rounded px-8 py-2 text-sm text-white mr-2" value="Sauvegarder"/>
                            <input type='button' className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 hover:bg-gray-300 border rounded px-8 py-2 text-sm text-gray-600" onClick={close} value="Annulé" />
                        </div>
                    </div>
                </div>
                </form>
            </div>
            <div id="event" className="absolute hidden top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-blanc p-8 rounded shadow-lg">
                <form onSubmit={updEvent}>
                    <div className="mb-4">
                    <label className="block text-bleu font-bold mb-2">Titre :</label>
                    <input
                        type="text"
                        name="titre"
                        id="titre"
                        className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-indigo-500"
                        value={eventData ? eventData.title : ''}
                        onChange={(e) => setEventData({ ...eventData, title: e.target.value })}
                    />
                    </div>
                    <div className="mb-4">
                        <label className="block text-bleu font-bold mb-2">Début :</label>
                        <div className="relative flex mb-5 mt-2 items-center rounded border border-grey pr-px focus-within:border-bleu">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-[3%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                            <DatePicker 
                                id="start" 
                                name='start' 
                                placeholderText="jj/mm/aaaa hh:mm" 
                                className="ml-[2%] text-gray-600 focus:outline-none font-normal  h-10 flex items-center text-sm" 
                                showTimeSelect 
                                locale="fr" 
                                dateFormat="dd/MM/yyyy HH:mm" 
                                selected={new Date(eventData.start)}
                                value={eventData.start ? moment(eventData.start).format('DD/MM/yyyy HH:mm') : ''}
                                onChange={handleStartDateChange}
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-bleu font-bold mb-2">Fin :</label>
                        <div className="relative flex mb-5 mt-2 items-center rounded border border-grey pr-px focus-within:border-bleu">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 ml-[3%]">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
                            </svg>
                            <DatePicker 
                                id="end" 
                                name='end' 
                                placeholderText="jj/mm/aaaa hh:mm" 
                                showTimeSelect 
                                locale="fr" 
                                dateFormat="dd/MM/yyyy HH:mm" 
                                selected={new Date(eventData.end)}
                                className="ml-[2%] text-gray-600 focus:outline-none font-normal  h-10 flex items-center text-sm border-grey" 
                                value={eventData.end ? moment(eventData.end).format('DD/MM/yyyy HH:mm') : ''}
                                onChange={handleEndDateChange}
                            />   
                        </div>
                    </div>
                    <div className="mb-4">
                    <label className="block text-bleu font-bold mb-2">Client :</label>
                    <input
                        type="text"
                        name="codeClient"
                        id="codeClient"
                        className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-indigo-500"
                        value={eventData ? eventData.codeClient : ''}
                        onChange={(e) => setEventData({ ...eventData, codeClient: e.target.value })}
                    />
                    </div>
                    <div className="mb-4">
                    <label className="block text-bleu font-bold mb-2">Emplacement :</label>
                    <input
                        type="text"
                        name="locationRdv"
                        id="locationRdv"
                        className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:ring focus:border-indigo-500"
                        value={eventData ? eventData.locationRdv : ''}
                        onChange={(e) => setEventData({ ...eventData, locationRdv: e.target.value })}
                    />
                    <input type="hidden" name="id" id="id" value={eventData ? eventData.id : ''} />
                    </div>
                    {isAuthenticated ? (
                    <div className="flex items-center">
                        <input
                        type="button"
                        className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 hover:bg-gray-300 border rounded px-8 py-2 text-sm text-gray-600"
                        onClick={close}
                        value="Fermer"
                        />
                    </div>
                    ) : (
                    <div className="flex items-center">
                        <input type='submit' className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-700 bg-indigo-700 hover:bg-indigo-600 border rounded px-8 py-2 text-sm text-white mr-2" value="Sauvegarder"/>
                        <input type='button' className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 bg-gray-100 hover:bg-gray-300 border rounded px-8 py-2 text-sm text-gray-600" onClick={close} value="Annulé" />
                    </div>
                    )}
                </form>
            </div>

        </>
    );
}