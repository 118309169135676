import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useParams } from "react-router-dom";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import db from '../../Db/db';
import FormAddTransporteur from "../Containers/formulaire/FormAddTransporteur"

function Transporteurs({ url }) {

    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [transporteur, setTransporteur] = useState([])
    const { id, codeAgence  } = useParams();

    useEffect(() => {
        async function fetchData() {
            const data2 = await db.transporteurCom.toArray();
            setTransporteur(data2.filter(t => t.codeClient === id && t.codeAgence === codeAgence));
        }

        fetchData();
    }, []);


    useEffect(() => {
        getTransporteur();
     }, [url]);

     function disabled(transporteur) {
        axios.delete(url+"client/disabledTransporteur/"+transporteur, {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        }).finally(fin=>{
            getTransporteur()
        })
            

        
      }

      function getTransporteur() {
        /*axios.get(url+'client/transporteurByCom/', {
            params: {
                client: id,
                agence: codeAgence
            },
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {       
            console.log(res.data)
            setTransporteur(res.data);
        })
        .catch(error=>{
            if(error.res.status === 401){
                console.log(error);
            }
            
        });*/
        async function fetchData() {
            const data2 = await db.transporteurCom.toArray();
            setTransporteur(data2.filter(t => t.codeClient === id && t.codeAgence === codeAgence));
        }

        fetchData();
      }

      function onSubmitSuccess (){
        getTransporteur();
      }


    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full bg-bleuBlanc">
                <div className="h-[15%] w-full">
                    <Title title="Avec qui travaillez-vous?" btn={false} />
                </div>
                <div className="p-2 h-[85%] w-full flex flex-row">
                    <div className='w-[80%] h-full font-bahn'>
                        <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[80%] border-collapse ">
                            <thead className='h-[6%] max-h-1.5'>
                                <tr className='bg-marineLogo text-blanc'>
                                    <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Nom du transporteur</th>
                                    <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Supprimer (disponible en ligne)</th>
                                </tr>
                            </thead>
                            <tbody className=' overflow-y-auto max-h-[94%] min-h-[50%]'>
                                {transporteur.map((t, index) => (
                                    <tr key={index} className='max-h-[10%] min-h-[5%]'>
                                        <td className=" border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-marineLogo">{t.name}</td>
                                        
                                        <td className='group'>
                                            <svg onClick={() => disabled(t.id)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="group-hover:stroke-roseLogo w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" /></svg>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className='w-[20%] h-full flex justify-end'>
                        <FormAddTransporteur url={url} token={userInfo.token} onSubmitSuccess={onSubmitSuccess} client={id} agence={codeAgence} />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Transporteurs;