import React, { useState, useEffect } from 'react';
import axios from "axios";
import db from '../../../Db/db';


function VisiteView({close, client, agence, url}) {
    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [agenceData, setAgenceData] = useState([])

    const [online, setOnline] = useState(true);
    
    const checkConnection = async () => {
        try {
            await axios.get('https://api.hautier-transports.fr/crm/');
            setOnline(true); 
            
        } catch (error) {
            setOnline(false);  
        }
    };
    let offlineFormData = JSON.parse(localStorage.getItem('New_contact')) || [];
        
        const saveOfflineData = (data, callback) => {
            offlineFormData.push(data);
            localStorage.setItem('New_contact', JSON.stringify(offlineFormData));
            callback();
        };

    const handleSubmit = (e) => {
      checkConnection(); 
        e.preventDefault();
        

        if(online){
          axios.post(url+"/client/newContact",{
              codeClient: client,
              codeAgence: agence,
              nom: e.target.nom.value,
              prenom: e.target.prenom.value,
              mailContact: e.target.email.value,
              telContact: e.target.num.value,
              fonction: e.target.fonction.value,
          },{
              headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
          }).then(response => {
            alert('Le contacte à bien été créé!');
            close();
        }).catch(function (error) {
            alert('Une erreur s\'est produite lors de la requête.');
            console.log(error);
        });
      }else{
          let dataToSend;
          dataToSend = {
            codeClient: client,
            codeAgence: agence,
            nom: e.target.nom.value,
            prenom: e.target.prenom.value,
            mailContact: e.target.email.value,
            telContact: e.target.num.value,
            fonction: e.target.fonction.value,
          }
        
          saveOfflineData(dataToSend, () => {
            alert('le contact a bien été enregistré')
        });
          close();
          
      }
    }

    useEffect(() => {
      async function fetchData() {
          const data2 = await db.agences.toArray();
          setAgenceData(data2);
      }

      fetchData();
  }, []);
    
    return(
        <div className="fixed inset-0 flex items-center justify-center z-50 ">
            <div className="bg-bleuBlanc w-[50%] max-lg:w-full p-4 rounded-lg shadow-lg border border-marineLogo min-h-[20%] max-h-[80%] overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100">
                <h2 className="text-[100%] font-semibold text-marineLogo mb-4">Formulaire création de contact</h2>
                <form onSubmit={handleSubmit} className='flex flex-col ' id='monFormulaire'>
                <div>
                <div className='flex'>
                  <div className="mb-4 mr-[3%] w-[30%]">
                    <label htmlFor="nom" className="block text-sm font-medium text-grey">Nom:</label>
                    <input type="text" name="nom" id="nom" className="mt-1 block w-full rounded-md border border-grey shadow-sm hover:border hover:border-vertLogo focus:outline-none focus:border focus:border-vertLogo"  required />

                  </div>
                  <div className="mb-4 mr-[3%] w-[30%]">
                    <label htmlFor="prenom" className="block text-sm font-medium text-grey">Prenom:</label>
                    <input type="text" name="prenom" id="prenom" className="mt-1 block w-full rounded-md border border-grey shadow-sm hover:border hover:border-vertLogo focus:outline-none focus:border focus:border-vertLogo" required />
                  </div>
                  <div className="mb-4 w-[30%]">
                    <label htmlFor="fonction" className="block text-sm font-medium text-grey">Fonction:</label>
                    <input type="text" name="fonction" id="fonction" className="mt-1 block w-full rounded-md border border-grey shadow-sm hover:border hover:border-vertLogo focus:outline-none focus:border focus:border-vertLogo" required />
                  </div>
                </div>
                <div className='flex'>
                  <div className="mb-4 mr-[3%] w-[30%]">
                    <label htmlFor="email" className="block text-sm font-medium text-grey">Email:</label>
                    <input type="email" name="email" id="email" className="mt-1 block w-full rounded-md border border-grey shadow-sm hover:border hover:border-vertLogo focus:outline-none focus:border focus:border-vertLogo" required />
                  </div>
                  <div className="mb-4 w-[30%]">
                    <label htmlFor="num" className="block text-sm font-medium text-grey">Numéro:</label>
                    <input type="tel" name="num" id="num" className="mt-1 block w-full rounded-md border border-grey shadow-sm hover:border hover:border-vertLogo focus:outline-none focus:border focus:border-vertLogo" required />
                  </div>
                </div>
              </div>
                <div className="flex justify-end">
                    <button
                        type="button"
                        className="hover:border hover:border-roseLogo hover:bg-blanc hover:text-roseLogo mr-2 px-4 py-2 rounded-md border border-marineLogo text-marineLogo bg-blanc focus:outline-none"
                        onClick={close}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="bg-vertLogo w-[20%] flex items-center rounded-lg text-blanc justify-center group hover:border hover:border-vertLogo focus:border focus:border-vertLogo hover:bg-blanc hover:text-vertLogo"
                        onClick={checkConnection()}
                    >
                        Soumettre
                    </button>
                </div>
                </form>
            </div>
        </div>
    )
}
export default VisiteView