import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import db from '../../Db/db';

function Top({ block, submitData }) {
    const [rowCount, setRowCount] = useState(10);
    const [filtreData, setFiltreData] = useState([]);
    const [filtreClients, setFiltreClients] = useState([]);
    const [clientAct, setClientAct] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const [data2, data3, data4] = await Promise.all([
                db.act.toArray(),
                db.top.toArray(),
                db.clients.toArray()
            ]);
            setClientAct(data2);
            setFiltreData(data3);
            setFiltreClients(data4);
        }
        fetchData();
    }, []);

    const handleRowCountChange = (event) => setRowCount(parseInt(event.target.value, 10));

    const filterBySearchTerm = (data, searchTerm) => {
        if (!searchTerm || searchTerm.trim() === '') return data;
    
        const searchTerms = searchTerm.split(' / ');
        const client = searchTerms[0]?.trim();
        const agence = searchTerms[2]?.trim();
        
        let filteredData = data;
    
        if (client) {
            filteredData = data.filter(item => item.codeClient === client);
        }
    
        if (agence) {
            filteredData = filteredData.filter(item => item.codeAgence === agence);
    
        }

        if (filteredData.length === 0) {
            const allAgences = filtreClients.find(c => c.codeClient === client && c.codeAgence === agence);
            if (allAgences) {
                filteredData.push({
                    codeClient: allAgences.codeClient,
                    codeAgence: allAgences.codeAgence,
                    sumCA: 0,
                    nom: allAgences.nom,
                    nomClient: allAgences.nomClient,
                    commercial: allAgences.commercial
                });
            }
        }
    
        return filteredData;
    };

    const filterByAgence = (data, selectedAgence) => {
        if (!selectedAgence || selectedAgence.trim() === '') return data;
        return data.filter(item => item.codeAgence === selectedAgence);
        
    };

    const filterBySelectedOptions = (data, selectedOptions) => {
        if (!selectedOptions || selectedOptions.length === 0) return data;

        const selectedActivityIds = selectedOptions.map(option => option.value);
        const selectedActMF = clientAct.filter(item =>
            item.idActMF && selectedActivityIds.some(id => item.idActMF.includes(id.toString()))
        );

        return data.filter(item =>
            selectedActMF.some(client => client.codeClient === item.codeClient && client.codeAgence === item.codeAgence)
        );
    };

    const filterByCommercial = (data, commercial) => {
        if (!commercial || commercial.length === 0) return data;
        return data.filter(item => item.commercial === commercial);
    };


    const filterByDate = (data, startDate, endDate) => {
        if (!startDate || !endDate) return data;
    
        const start = new Date(startDate);
        const end = new Date(endDate);
        

        start.setHours(0, 0, 0, 0);
        end.setHours(23, 59, 59, 999); 
    
        const filteredData = data.filter(item => {
            const itemDate = new Date(item.annee, item.mois - 1);
            return itemDate >= start && itemDate <= end;
        });
    
        if (filteredData.length > 0) return filteredData;
        return data;
    };
    

    const applyFilters = (data) => {
        let filteredData = [...data];

        filteredData = filterBySearchTerm(filteredData, submitData?.searchTerm);
        filteredData = filterByAgence(filteredData, submitData?.selectedAgence);
        filteredData = filterBySelectedOptions(filteredData, submitData?.selectedOptions);
        filteredData = filterByCommercial(filteredData, submitData?.commercial);
        filteredData = filterByDate(filteredData, submitData?.startDate, submitData?.endDate);
        return filteredData;
    };

    const aggregateDataByClientAndAgence = (data) => {
        console.log(data)
        const sumByClientAndAgence = {};

        data.forEach(item => {
            const { codeClient, codeAgence, CA, nom, nomClient, commercial } = item;
            if (!sumByClientAndAgence[codeClient]) {
                sumByClientAndAgence[codeClient] = {};
            }
            if (!sumByClientAndAgence[codeClient][codeAgence]) {
                sumByClientAndAgence[codeClient][codeAgence] = {
                    sumCA: 0,
                    nom,
                    nomClient,
                    commercial
                };
            }
            const caValue = CA !== null ? parseFloat(CA) : 0;
            sumByClientAndAgence[codeClient][codeAgence].sumCA += caValue;
        });

        return Object.entries(sumByClientAndAgence).flatMap(([codeClient, agences]) =>
            Object.entries(agences).map(([codeAgence, data]) => ({
                codeClient,
                codeAgence,
                ...data
            }))
        );
    };

    let clientData = [];
    if (submitData && Object.keys(submitData).length > 0) {
        const filteredData = applyFilters(filtreData);
        console.log(filteredData)
        const aggregatedData = aggregateDataByClientAndAgence(filteredData);
        console.log(aggregatedData)
        clientData = aggregatedData.sort((a, b) => b.sumCA - a.sumCA).slice(0, rowCount);
    } else {
        const currentYear = new Date().getFullYear();
        const currentMonth = new Date().getMonth() + 1;

        const filteredData = filtreData.filter(top =>
            top.annee === currentYear && top.mois >= 1 && top.mois <= currentMonth
        );
        const aggregatedData = aggregateDataByClientAndAgence(filteredData);
        clientData = aggregatedData.sort((a, b) => b.sumCA - a.sumCA).slice(0, rowCount);
    }

    return (
        <div className='font-bahn bg-blanc border border-marineLogo rounded-lg flex flex-col h-full w-[100%] overflow-x-hidden overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-roseLogo scrollbar-track-grey100 max-h-full'>
            <div className='border-b border-marineLogo p-[4px] mb-2 text-marineLogo'>
                <span>Mon top </span>
                <select
                    id="rowCount"
                    className="px-2 py-1 border rounded text-xs"
                    value={rowCount}
                    onChange={handleRowCountChange}
                >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                </select>
            </div>
            <div className='flex items-center justify-center'>
                <table className="table-fixed max-h-[70%] border-collapse items-center bg-transparent w-[98%] border-collapse ">
                    <thead className='h-[6%] max-h-1.5 text-blanc'>
                        <tr className='bg-marineLogo'>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[60%]">Nom client</th>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left w-[20%]">Agence</th>
                            <th className="sticky align-middle border border-solid border-marineLogo text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center w-[20%]">CA N</th>
                        </tr>
                    </thead>
                    <tbody className='overflow-y-auto max-h-[94%] min-h-[50%]'>
                        {clientData.slice(0, rowCount).map((member, index) => (
                            <tr key={index} className='max-h-[10%] min-h-[5%]'>
                                <Link to={`/profil/client/${member.codeClient}/${member.codeAgence}`} className="focus:outline-none text-left text-marineLogo font-bold flex justify-start items-center w-full h-full p-[1%]">
                                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left">{member.nomClient} ({member.codeClient})</td>
                                </Link>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">{member.nom}</td>
                                <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap pr-[5%] text-right">
                                {member.sumCA !== null && member.sumCA !== undefined && !isNaN(parseFloat(member.sumCA))
                                    ? parseFloat(member.sumCA).toLocaleString('fr-FR', { minimumFractionDigits: 2 })
                                    : "0,00"} €
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Top;
