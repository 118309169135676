import React, {useState, useEffect}  from 'react';
import axios from "axios";
import Sidebar from '../SideBar/Sidebar';
import Title from '../Containers/Title';
import DatePicker, {registerLocale, setDefaultLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import fr from 'date-fns/locale/fr';
import {Link} from "react-router-dom";

registerLocale('fr', fr);
setDefaultLocale('fr');

export default function TopClients({url}) {

    const json = localStorage.getItem("token")
    const userInfo = JSON.parse(json);
    const [clientData, setClientData] = useState([]);

    const [rowCount, setRowCount] = useState(10);

      const handleRowCountChange = (event) => {
          setRowCount(parseInt(event.target.value, 10));
      };


    useEffect(() => {
        axios.get(url+'client/', {
            headers: {
                "Access-Control-Allow-Origin": "*",
                "Content-type": "Application/json",
                'Authorization': 'Bearer ' + userInfo.token
            }
        })
        .then(res => {     
            setClientData(res.data)
        })
        .catch(error=>{
            console.log(error);
            
        });
    }, [url]);

        

    return (
        <div className='flex flex-row h-full w-full fixed'>
            <Sidebar />
            <div className="relative flex flex-col w-full h-full">
                
                <Title title="Top Clients"/>
            
                <div className='flex h-full w-full'>
                    <div className='w-[80%] max-lg:hidden h-full'>
                            <div className='h-[3%] mb-2'>
                                <label htmlFor="rowCount" className="mr-2">Top :</label>
                                <select
                                    id="rowCount"
                                    className="px-2 py-1 border rounded "
                                    value={rowCount}
                                    onChange={handleRowCountChange}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                </select>
                            </div>
                            <div className='h-full font-bahn'>
                                <table className="table-fixed h-[70%] border-collapse items-center bg-transparent w-[70%] border-collapse ">
                                    <thead className='h-[6%]'>
                                        <tr className='bg-bleu'>
                                            <th className="sticky   align-middle border border-solid border-bleu  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Code client</th>
                                            <th className="sticky   align-middle border border-solid border-bleu  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Nom client</th>

                                            <th className="sticky   align-middle border border-solid border-bleu  text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Agence</th>
                                            <th className="sticky   align-middle border border-solid border-bleu text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">CA</th>
                                        </tr>
                                    </thead>
                                    <tbody className=' overflow-y-scroll max-h-[100%]'>
                                    {clientData.slice(0, rowCount+1).map(member => (
                                        <tr className='max-h-[15%] min-h-[10%]'>
                                            <Link to={"/profil/client/" + member.codeClient + "/" + member.codeAgence} className="focus:outline-none focus:text-indigo-400 text-left text-white flex justify-start items-center w-full h-full p-[1%]">
                                            <td className=" border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap text-left text-bleu ">{member.codeClient}</td>
                                            </Link>
                                            <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{member.nomClient}</td>

                                            <td className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap ">{member.nom}</td>
                                            <td className="border-t-0  align-middle border-l-0 border-r-0 text-xs whitespace-nowrap  ">{member.CA} €</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    );
}