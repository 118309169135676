import Dexie from 'dexie';


const db = new Dexie('Crm');


db.version(1).stores({
    clients: '[codeClient+codeAgence]',
    agences: '++id',
    activite: '++id',
    team: '++id',
    etapes: '++id',
    top: '++id',
    top30: '++id',
    ca: '++id',
    caYear: '++id',
    visites: '++id',
    visitePrevue: '++id',
    visitePrevoir: '++id',
    cityCharg: '++id',
    cityLivr: '++id',
    tansporteur: '++id',
    transporteurCom: '++id',
    contacts: '++id',
    act: '++id',
    depart: '++id',
    energies: '++id',
    conso: '++id',
    adem: '++id',
});
 

export default db;